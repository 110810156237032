<template>
    <div class="icon">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.18639 5.0637C1.91202 5.0637 0.878906 4.0306 0.878906 2.75622C0.878906 1.48184 1.91202 0.44873 3.18639 0.44873C4.46077 0.44873 5.49388 1.48184 5.49388 2.75622C5.49388 4.0306 4.46083 5.0637 3.18639 5.0637ZM4.97145 16.776C4.97145 17.0912 4.71599 17.3466 4.40084 17.3466H1.97193C1.65678 17.3466 1.40133 17.0912 1.40133 16.776V6.59411C1.40133 6.27896 1.65678 6.02351 1.97193 6.02351H4.40084C4.71599 6.02351 4.97145 6.27896 4.97145 6.59411V16.776ZM17.1403 17.3466C17.43 17.3466 17.6649 17.1117 17.6649 16.822V11.8941V11.8578C17.665 9.70797 17.6653 5.85266 13.5153 5.85266C11.6306 5.85266 10.7454 6.54259 10.1498 7.43646V6.54815C10.1498 6.25843 9.91485 6.02351 9.62513 6.02351H7.10429C6.81456 6.02351 6.57965 6.25843 6.57965 6.54815V16.822C6.57965 17.1117 6.81456 17.3466 7.10429 17.3466H9.62513C9.91491 17.3466 10.1498 17.1117 10.1498 16.822V11.3135C10.2152 10.5733 10.541 8.92407 12.1473 8.92407C14.0656 8.92407 14.0276 10.9917 14.0116 11.8627C14.0103 11.9319 14.0092 11.9936 14.0092 12.0461V16.822C14.0092 17.1117 14.2441 17.3466 14.5339 17.3466H17.1403Z" fill="white"/>
        </svg>
    </div>
</template>

<style lang="scss">
@import "../../assets/styles/main.scss";

.icon {
    // color: #8A8F96;
    background-color: rgba(250, 251, 252, 0.1);
    display: flex;
    justify-content: center;
    border-radius: 4px;
    height: 30px;
    width: 30px;  
}

</style>

