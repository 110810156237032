<template>
    <div class="everestBanner">
        <div class="bannerContent">
            <div class="bannerTextContainer">
                <p>Due to planned system maintenance, order processing will be delayed on any order placed. We apologize for any inconvenience.</p>
            </div>
            <img class="bannerImage" src="../assets/images/banner-image.jpeg" alt="">
        </div>
    </div>
</template>

<script>
export default {
    name: 'EverestDowntimeBanner',
}
</script>

<style lang="scss">
  @import "../assets/styles/main.scss";
  .everestBanner {
    display: flex;
    justify-content: center;
    margin-bottom: 96px;
}

  .bannerContent {
    text-align: center;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 89px !important;
    width: 1104px;
    background: linear-gradient(to bottom, #8A008D, #0B34C5);
    border: 1px solid #5856D6;
    border-radius: 8px;
}

.bannerTextContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px; /* Optional: add some padding for better text spacing */
    z-index: 2;
    p {
        color: white !important;
        font-size: 16px !important;
        font-weight: 700 !important;
        width: 100%; /* Make the paragraph take up the full width of the container */
        text-align: center; /* Ensure the text is centered */
    
    }
}

.bannerImage {
    height: 100%;
    width: 100%;
    opacity: 0.1;
    object-fit: cover;
}

@media (max-width: 1104px) {
    .bannerContent {
        padding: 10px 10px 10px 10px;
        width: 90%;
    }
}
</style>
