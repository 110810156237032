<template>
  <img src="https://cdn.zoominsoftware.io/jamf-skin-prod/public/assets/img/home_page_bg_full.png?0340a76" alt="" class="img-bg">
  <div class="wrapper">
    <div class="store-content">
      <Header />
      <router-view/>
      <Footer />
    </div>
  </div>
</template>

<script>
  import Header from "./components/Header.vue";
  import Footer from "./components/Footer.vue";

  export default {
  name: "App",
  components: {
    Header,
    Footer
  },
  created() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // We listen to the resize event
    window.addEventListener('resize', () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
});
  },
};

</script>

<style lang="scss">
#app {
  overflow: hidden;
  position: relative;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.store-content {
  overflow-y: auto;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.img-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  opacity: .3;
}
</style>
