import { createStore } from 'vuex'

function AnalyticsIntegrations() {
  'use strict';

  // updated integrations object for GA4 to be used in place of defaultIntegrations for GA4 events
  var GA4Integrations = {
      integrations: {
          'All': false,
          'Actions Google Analytic 4': true,
          'Google Analytics 4 Web': true
      }
  }

  // default integrations object
  var defaultIntegrations = {
      integrations: {
          'All': true
      }
  };

  return {
      GA4Integrations: GA4Integrations,
      defaultIntegrations: defaultIntegrations
  };
}

export default createStore({
  state: {
    everestDowntimeBanner: false,
    businessPlan: {
      sku: 999999,
      title: "Jamf Business Plan",
      description: "Complete management and security: automated deployment and configuration, identity-based access, endpoint security and threat prevention.",
      features: [
        "Jamf Pro",
        "Jamf Protect",
        "Jamf Connect",
      ],
      price: "$172.08", 
      priceDetail: "per user/per year",
      cta1: "https://store.jamf.com/1594/purl-storebusinessplanbuy",
      cta2: "https://store.jamf.com/1594/purl-storebusinessplanquote",
    },
    fundamentals: {
      sku: 999999,
      title: "Jamf Now",
      description: "Streamlined management and security workflows with basic identity management (password sync) and malware protection",
      features: [
        "Jamf Now",
        "Core Apple Enterprise Management features",
        "Phone, email and chat support",
      ],
      price: "$48", 
      priceDetail: "per device/per year",
      cta1: "https://store.jamf.com/1594/purl-storefundamentalsbuy",
      cta2: "https://store.jamf.com/1594/purl-storefundamentalsquote",
    },
    enterprise: {
      sku: 999999,
      title: "Jamf Enterprise Plan",
      description: "Everything in Business plan with enterprise-grade support and premium cloud features for complex Apple environments. Both user- and device-based pricing options",
      features: [
        "Jamf Pro",
        "Jamf Protect",
        "Jamf Connect",
        "Jamf Premium Cloud",
        "Jamf Premium Support",
      ],
      price: null, 
      priceDetail: null,
      cta1: "https://www.jamf.com/pricing/enterprise-plan/ ",
      cta2: null,
    },
    eduEnhanced: {
      sku: 999999,
      title: "Jamf Education Enhanced Bundle",
      description: "Simple management and security: Jamf School (MDM), Jamf Safe Internet (threat prevention and content filtering)",
      features: [
        "Jamf School",
        "Jamf Safe Internet",
        "Enhanced Support",
      ],
      price: "$9", 
      priceDetail: "per device/per year",
      cta1: "https://store.jamf.com/1594/purl-storeenhancedbuy ",
      cta2: "https://store.jamf.com/1594/purl-storeenhancedquote ",
    },
    eduUltimate: {
      sku: 999999,
      title: "Jamf Education Ultimate Bundle",
      description: "Complete package: Jamf School, Jamf Safe Internet, Jamf Protect (Mac endpoint protection), Jamf Connect (identity and authentication management)",
      features: [
        "Jamf School",
        "Jamf Protect",
        "Jamf Connect",
        "Jamf Safe Internet",
        "Enhanced Support",
      ],
      price: "$20", 
      priceDetail: "per device/per year",
      cta1: "https://store.jamf.com/1594/purl-storeultimatebuy",
      cta2: "https://store.jamf.com/1594/purl-storeultimatequote",
    },
    management: [
      {
        sku: 999999,
        title: "Jamf Pro",
        description: "Apple management to meet the needs of any organization",
        features: [],
        price1: "$45", 
        price2: "$94.68",
        cta1: "https://store.jamf.com/1594/purl-storeprobuy",
        cta2: "https://store.jamf.com/1594/purl-storeproquote",
        buttonText: "Buy Now",
      },
      {
        sku: 999999,
        title: "Jamf Now",
        description: "Simple Apple management for teams and growing businesses",
        features: [],
        price: "$48", 
        priceDetail: "per device/per year",
        cta1: "https://store.jamf.com/1594/purl-storenowbuy",
        cta2: "https://store.jamf.com/1594/purl-storenowquote",
        buttonText: "Buy Now",
      },
    ],
    security: [
      {
        sku: 999999,
        title: "Jamf Connect",
        description: "Modern and secure access to applications and resources",
        features: [],
        price: "$48", 
        priceDetail: "per device/per year",
        cta1: "https://store.jamf.com/1594/purl-storeconnectbuy ",
        cta2: "https://store.jamf.com/1594/purl-storeconnectquote",

        buttonText: "Buy Now",
      },
      {
        sku: 999999,
        title: "Jamf Protect",
        description: "Comprehensive endpoint security, threat defense, web threat prevention and content filtering",
        features: [],
        price: "$72", 
        priceDetail: "per device/per year",
        cta1: "https://store.jamf.com/1594/purl-storeprotectbuy",
        cta2: "https://store.jamf.com/1594/purl-storeprotectquote",

        buttonText: "Buy Now",
      },
      {
        sku: 999999,
        title: "Jamf Executive Threat Protection",
        description: "Protect high value users from sophisticated threats that target mobile devices.",
        features: [],
        price: null, 
        priceDetail: null,
        cta1: "https://www.jamf.com/pricing/commercial-plans/",
        cta2: null,
        buttonText: "Request Trial",
      },
      {
        sku: 999999,
        title: "Jamf Mobile Security",
        description: "Comprehensive mobile security including mobile threat defense (MTD), web threat prevention, content filtering, vulnerability management and modern zero trust access (ZTNA).",
        features: [],
        price: "$60", 
        priceDetail: "per device/per year",
        cta1: "https://store.jamf.com/1594/purl-storesecuritybuy",
        cta2: "https://store.jamf.com/1594/purl-storesecurityquote",
        buttonText: "Buy Now",
      },
    ],
    eduManagement: [
      {
        sku: 999999,
        title: "Jamf Pro",
        description: "Apple management to meet the needs of any organization",
        features: [],
        price1: "$9", 
        price2: "$18",
        cta1: "https://store.jamf.com/1594/purl-storeproedubuy",
        cta2: "https://store.jamf.com/1594/purl-storeproeduquote",
        buttonText: "Buy Now",
      },
      {
        sku: 999999,
        title: "Jamf School - Subscription",
        description: "Complete with teacher, student and parent apps, Jamf School creates the optimal digital experience and ensures students are focused and engaged",
        features: [],
        price: "$5.50", 
        priceDetail: "per device/per year",
        cta1: "https://store.jamf.com/1594/purl-storeschoolbuy",
        cta2: "https://store.jamf.com/1594/purl-storeschoolquote",
        buttonText: "Buy Now",
      },
      {
        sku: 999999,
        title: "Jamf School - Lifetime",
        description: "Complete with teacher, student and parent apps, Jamf School creates the optimal digital experience and ensures students are focused and engaged",
        features: [],
        price: "$17.50", 
        priceDetail: "Lifetime of an iOS, macOS, or tvOS device",
        cta1: "https://store.jamf.com/1594/purl-storelifetimebuy",
        cta2: "https://store.jamf.com/1594/purl-storelifetimequote",
        buttonText: "Buy Now",
      },
    ],
    eduSecurity: [
      {
        sku: 999999,
        title: "Jamf Connect",
        description: "Modern and secure access to applications and resources",
        features: [],
        price: "$5", 
        priceDetail: "per device/per year",
        cta1: "https://store.jamf.com/1594/purl-storeconnectedubuy ",
        cta2: "https://store.jamf.com/1594/purl-storeconnecteduquote ",
        buttonText: "Buy Now",
      },
      {
        sku: 999999,
        title: "Jamf Protect",
        description: "Comprehensive endpoint security, threat defense, web threat prevention and content filtering",
        features: [],
        price: "$12", 
        priceDetail: "per device/per year",
        cta1: "https://store.jamf.com/1594/purl-storeprotectedubuy",
        cta2: "https://store.jamf.com/1594/purl-storeprotecteduquote",
        buttonText: "Buy Now",
      },
      {
        sku: 999999,
        title: "Jamf Safe Internet",
        description: "Content filtering and network threat protection for education",
        features: [],
        price: "$5", 
        priceDetail: "per device/per year",
        cta1: "https://store.jamf.com/1594/purl-storesafeinternetbuy ",
        cta2: "https://store.jamf.com/1594/purl-storesafeinternetquote",
        buttonText: "Buy Now",
      },
    ],
    addOns: [
      {
        sku: 999999,
        title: "Premium Cloud",
        description: "Add-on for cloud hosting that provides you with flexibility and control over your server. Choose when you’d like to upgrade, safelist IPs, customize your URL and more",
        features: [],
        price: null, 
        priceDetail: null,
        cta1: "https://www.jamf.com/resources/product-documentation/jamf-premium-cloud/",
        cta2: null,
        buttonText: "Learn More",
        industryBadgeClass: "all-industries",
        productBadgeClass: "jamf-pro"
      },
      {
        sku: 999999,
        title: "Jamf Setup & Reset",
        description: "Wireless, single sign-on cloud ID workflow equips iOS or iPadOS devices for frontline workers",
        features: [],
        price: null, 
        priceDetail: null,
        cta1: "https://www.jamf.com/pricing/single-login/",
        cta2: null,
        buttonText: "Contact Sales",
        industryBadgeClass: "healthcare",
        productBadgeClass: "jamf-pro"
      },
      {
        sku: 999999,
        title: "Virtual Visits",
        description: "Remote and secure in-room video calls for providers and families; one-click care access",
        features: [],
        price: null, 
        priceDetail: null,
        cta1: "https://www.jamf.com/pricing/virtual-visits/",
        cta2: null,
        buttonText: "Contact Sales",
        industryBadgeClass: "healthcare",
        productBadgeClass: "jamf-pro"
      },
      {
        sku: 999999,
        title: "Healthcare Listener",
        description: "Simple patient iPad and Apple TV setup, app deployment and auto device wiping",
        features: [],
        price: null, 
        priceDetail: null,
        cta1: "https://www.jamf.com/pricing/healthcare-listener/",
        cta2: null,
        buttonText: "Contact Sales",
        industryBadgeClass: "healthcare",
        productBadgeClass: "jamf-pro"
      },
      {
        sku: 999999,
        title: "Jamf BYOD",
        description: "Manage and secure personally-owned devices while maintaining end-user privacy and protection",
        features: [],
        price: null, 
        priceDetail: null,
        cta1: "https://www.jamf.com/request-trial/",
        cta2: null,
        buttonText: "Contact Sales",
        industryBadgeClass: "all-industries",
        productBadgeClass: "jamf-pro"
      },
    ],
    services: [
      {
        sku: 999999,
        title: "Onboarding",
        description: "A variety of onboarding services with multiple delivery methods to ensure you are managing your Apple fleet confidently",
        features: [],
        price: null, 
        priceDetail: null,
        cta1: "https://www.jamf.com/services/onboarding/",
        cta2: null,
        buttonText: "Learn More",
        industryBadgeClass: "all-products",
        productBadgeClass: null
      },
      {
        sku: 999999,
        title: "Premium Support",
        description: "Complements our legendary customer support to help you achieve your strategic goals and immediately address any emergencies that may arise",
        features: [],
        price: null, 
        priceDetail: null,
        cta1: "https://www.jamf.com/support/jamf-pro/premium/",
        cta2: null,
        buttonText: "Learn More",
        industryBadgeClass: "all-products",
        productBadgeClass: null
      },
    ]
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    trackCTAClicked({ commit, state, getters }, info) {
      //GA4 Event Tracker
      analytics.track(
        'Jamf Store Event',
        {
          parameter: 'Selected ' + info.clicked,
          value: info.product
        },
        {
          integrations: {
            'All': false,
            'Actions Google Analytic 4': true,
            'Google Analytics 4 Web': true
          }
        }
      )
    },
  },
  modules: {
  }
})
