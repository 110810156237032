<template>
    <div :class="[{ 'top-nav': !toggled, 'top-nav-menu-collapse': toggled }, { 'top-nav-small-margin': this.$store.state.everestDowntimeBanner }]">
        <div class="left-section">
          <jamf-link to="https://www.jamf.com" id="jamf-logo"><img id="logo" src="../assets/images/jamf-color-dark.png" alt="jamf icon"/></jamf-link>
            <jamf-link :to="getStoreRoute()"><h5 id="title">Store</h5></jamf-link>
        </div>
        <div class="right-section">
            <jamf-link class="header-btn" to="https://www.jamf.com/lp/ecom-contact/" buttonStyleType="secondary" asButton="true" target="_blank">
                <jamf-icon class="chat-icon" :data=chat width="17" />
                Contact</jamf-link>
            <jamf-link class="header-btn" to="https://www.jamf.com/request-trial/" @click="buttonHandler()" asButton="true" buttonStyleType="secondary" target="_blank">Start Trial</jamf-link>
        </div>

        <div class="right-section-mobile">
          <jamf-button id="menu" styleType="ghost" :onClick="toggleMenu">
            <jamf-icon :data="hamburgerMenu" width="22" />
          </jamf-button>
        </div>
    </div>  
    <div :id="getMobileMenuClass()">
      <jamf-link id="header-link" to="https://www.jamf.com/lp/ecom-contact/"><jamf-icon class="chat-icon" :data=chat width="17" />Contact</jamf-link>
      <jamf-link id="trial-link" to="https://www.jamf.com/request-trial/">Start Trial</jamf-link>
    </div>
    <EverestDowntimeBanner v-if="this.$store.state.everestDowntimeBanner"/>
  </template>
  
  <script>
  import { JamfButton, JamfIcon, JamfLink } from "@jamf/design-system-vue";
  import EverestDowntimeBanner from "./EverestDowntimeBanner.vue";
  import chat from "@icon/chat.svg";
  import hamburgerMenu from "@icon/hamburger-menu.svg";

  export default {
    name: "Header",
    components: {
        JamfButton,
        JamfIcon,
        JamfLink,
        EverestDowntimeBanner
    },
    props: {
      msg: String,
    },
    data() {
    return {
        chat,
        hamburgerMenu,
        toggled: false
    }
    },
    methods: {
      toggleMenu() {
        console.log(this.toggled)
        this.toggled == true ? this.toggled = false : this.toggled = true;
      },
      getMobileMenuClass() {
        if(this.toggled) {
          return 'active'
        } else {
          return 'inactive'
        }
      },
      goToHome() {
        this.$router.push({ name: 'home' })
      },
      getStoreRoute() {
        const env = process.env.VUE_APP_ENV; 
        let route = env === "production" ? `https://store-configure.jamf.com/store` : `https://store-configure.jamfnebula.com/store`
        return route
      },
      buttonHandler() {
        this.$store.dispatch("trackCTAClicked", {
            clicked: 'Start Trial',
            product: 'Jamf Store'})
      }
    }
  };
  </script>
  
  <style lang="scss">
  @import "../assets/styles/main.scss";
  .top-nav {
    display: flex;
    height: 64px;
    min-height: 64px;
    justify-content: space-between;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    margin-bottom: 96px
  }
  .top-nav-menu-collapse {
    display: flex;
    height: 64px;
    min-height: 64px;
    justify-content: space-between;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    margin-bottom: 96px
  }
  .top-nav-small-margin {
    margin-bottom: 35px;
  }
  #logo {
    float: left;
    margin-right: 23px;
    margin-left: 32px;
    max-width: 73%;
    max-height: 93%;
    // object-fit: cover;
    border-right: 0.622807px solid rgba(0, 0, 0, 0.15);
    padding-right: 23px;
  }

  .right-section {
    display: flex;
    align-items: center;
    .header-btn {
        margin-right: 20px;
    }
    margin-right: 50px;
  }

  .left-section {
    display: flex;
    align-items: center;
  }

  .chat-icon {
    margin-right: 10px;
  }

  #title {
    font-weight: 400;
    font-size: 15px;
    cursor: pointer;
  }

  .right-section-mobile {
    display: none;
}

  #active {
    display: none;
    margin-bottom: 96px;
  }

  #inactive {
    display: none;
  }

#menu {
  border-radius: 0;
}

@media screen and (max-width: 730px) {
    .right-section {
      display: none;
    }
    .right-section-mobile {
      display: flex;
      align-items: center;
      margin-right: 5px;
    }

    #inactive {
      display: none;
    }

    #active {
      display: flex;
      flex-direction: column;
      background: white;
      padding: 10px 0 10px 0;
      margin-bottom: 96px;
      height: 100%;
      width: 100%;
      a {
        width: 100%;
        display: flex;
        justify-content: center;
        color: black;
        text-decoration: none;
        padding-bottom: 30px;
        padding-top: 30px;
        font-size: 16px;
      }
      a:hover {
        background: #F4F4F4
      }
    }
    .top-nav-menu-collapse {
      display: flex;
      height: 64px;
      min-height: 64px;
      justify-content: space-between;
      background: #FFFFFF;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
      margin: 0;
    }
    .chat-icon {
        display: none;
      }
  }

@media screen and (max-width: 600px) {
    .right-section {
      display: none;
    }
    .right-section-mobile {
      display: flex;
      align-items: center;
      margin-right: 5px;
    }

    #inactive {
      display: none;
    }

    #active {
      display: flex;
      flex-direction: column;
      background: white;
      padding: 10px 0 10px 0;
      margin-bottom: 96px;
      height: 100%;
      width: 100%;
      a {
        width: 100%;
        display: flex;
        justify-content: center;
        color: black;
        text-decoration: none;
        padding-bottom: 30px;
        padding-top: 30px;
        font-size: 16px;
      }
      a:hover {
        background: #F4F4F4
      }
    }
    .top-nav-menu-collapse {
      display: flex;
      height: 64px;
      min-height: 64px;
      justify-content: space-between;
      background: #FFFFFF;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
      margin: 0;
    }
    .chat-icon {
        display: none;
      }
  }

  @media screen and (max-width: 455px) {
    .right-section {
      display: none;
    }

    .right-section-mobile {
      display: flex;
      align-items: center;
      margin-right: 5px;
    }

    #inactive {
      display: none;
    }

    #active {
      display: flex;
      flex-direction: column;
      background: white;
      padding: 10px 0 10px 0;
      margin-bottom: 96px;
      height: 100%;
      width: 100%;
      a {
        width: 100%;
        display: flex;
        justify-content: center;
        color: black;
        text-decoration: none;
        padding-bottom: 30px;
        padding-top: 30px;
        font-size: 16px;
      }
      a:hover {
        background: #F4F4F4
      }
    }
    .top-nav-menu-collapse {
      display: flex;
      height: 64px;
      min-height: 64px;
      justify-content: space-between;
      background: #FFFFFF;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
      margin: 0;
      }
    .chat-icon {
      display: none;
    }
  }
  </style>
  