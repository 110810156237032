<template>
  <div class="footer">
    <div class="help-content">
      <h3>Need Help?</h3>
      <JamfLink to="https://www.jamf.com/lp/ecom-contact" asButton="true" buttonStyleType="secondary" id="contact-link" target="blank">Contact Us</JamfLink>
      <p>Are you already a Jamf Customer? <JamfLink to="https://account.jamf.com/products/" target="blank">Manage Subscription Here.</JamfLink></p>
      <p>Find a reseller? <JamfLink to="https://www.jamf.com/partners/directory/#/">Contact Us.</JamfLink></p>
    </div>
    <div class="footer-content">
      <div class="footer-top-middle-container">
        <div class="footer-top-container">
          <div class="logo-container">
            <img 
            class="logo"
            src="../assets/images/jamf-color-dark.png"
            alt="jamf icon"
          />
          </div>
          <div class="prod-disclaimer">Not all products available for online purchase in all countries. <JamfLink to="https://www.jamf.com/lp/ecom-contact" target="blank">Contact Jamf</JamfLink> for more info.</div>
          
        </div>
        <div class="footer-middle-container">
        <div class="footer-left-col">
          <h4 id="footer-subtitle">Jamf</h4>
          <JamfLink
              class="link"
              to="https://www.jamf.com/lp/ecommerce-faqs"
              target="_blank"
              >FAQs</JamfLink
          >
          <JamfLink
              class="link"
              to="https://www.jamf.com/lp/ecom-contact"
              target="_blank"
              >Contact Sales</JamfLink
          >
          <JamfLink
              class="link"
              to="https://account.jamf.com/support"
              target="_blank"
              >Get Support</JamfLink
          >
          <JamfLink
              class="link"
              to="https://www.jamf.com"
              target="_blank"
              >Jamf.com</JamfLink
          >
          <JamfLink
              class="link"
              to="https://community.jamf.com/"
              target="_blank"
              >Jamf Nation</JamfLink
          >
        </div>
        <div class="footer-middle-col">
          <h4 id="footer-subtitle">Cleverbridge</h4>
          <JamfLink
              class="link"
              to="https://store.jamf.com/1594/?scope=optandc&id=VuDpWa8lCy"
              target="_blank"
              >Terms & Conditions</JamfLink
          >
          <JamfLink
              class="link"
              to="https://store.jamf.com/1594/?scope=opcancelation&id=m6V6ah2o6o"
              target="_blank"
              >Right of Revocation</JamfLink
          >
          <JamfLink
              class="link"
              to="https://store.jamf.com/1594/?scope=opsecurity&id=orB760uj6M"
              target="_blank"
              >Security</JamfLink
          >
          <JamfLink
              class="link"
              to="https://store.jamf.com/1594/?scope=opimprint&id=m6V6ah2o6o"
              target="_blank"
              >Legal Info</JamfLink
          >
          <JamfLink
              class="link"
              to="https://store.jamf.com/1594/?scope=opprivacy&id=m6V6ah2o6o"
              target="_blank"
              >Privacy</JamfLink
          >
          <JamfLink
              class="link"
              to="https://store.jamf.com/1594/?scope=optandc&id=m6V6ah2o6o"
              target="_blank"
              >Cookies Settings</JamfLink
          >
          <span id="cleverbridge-copyright">© Cleverbridge GmbH 2023</span>
        </div>
        <div class="footer-right-container">
        <div v-if="countryCode !== 'US'" class="footer-right-col">
          This order process is conducted by our online reseller Clverbridge GmbH. 
          Payment processing and order fulfillment are done by Cleverbridge GmbH, Gereonstr. 
          43-65, 50670 Cologne, Germany.
          <JamfLink to="https://store.jamf.com/1594/?scope=oppcidss&id=FU9lP0YQQD" target="blank">
            <img id="compliance-logo" src="../assets/images/PciDssCertificate.png">
          </JamfLink>
        </div>
        <div v-if="countryCode === 'US'" class="footer-right-col">
          This order process is conducted by our online reseller Cleverbridge, Inc. 
          Payment processing and order fulfillment are done by Cleverbridge, Inc., 350 N Clark, Suite 700, 
          Chicago, IL 60654, United States.
          <JamfLink to="https://store.jamf.com/1594/?scope=oppcidss&id=FU9lP0YQQD" target="blank">
            <img id="compliance-logo" src="../assets/images/PciDssCertificate.png">
          </JamfLink>
        </div>
        </div>
      </div>
    </div>
    </div>
    <div class="footer-bottom-container">
        <div class="footer-bar-left">
          <JamfLink
              class="link"
              to="https://www.jamf.com/copyright-notice/"
              target="_blank"
              >Copyright</JamfLink
          >
          <JamfLink
              class="link"
              to="https://www.jamf.com/trust-center/privacy/"
              target="_blank"
              >Privacy Policy</JamfLink
          >
          <JamfLink
              class="link"
              to="https://www.jamf.com/terms-of-use/"
              target="_blank"
              >Terms of Use</JamfLink
          >
          <JamfLink
              class="link"
              to="https://www.jamf.com/trust-center/"
              target="_blank"
              >Trust</JamfLink
          >
          <JamfLink
              class="link"
              to="https://www.jamf.com/modern-slavery-act-statement/"
              target="_blank"
              >Modern Slavery Act Statement</JamfLink
          >
        </div>
        <div class="footer-bar-right">
          <JamfLink to="https://twitter.com/jamfsoftware" target="blank"><TwitterIcon></TwitterIcon></JamfLink>
          <JamfLink to="https://www.linkedin.com/company/jamf-software/" target="blank"><LinkedInIcon></LinkedInIcon></JamfLink>
          <JamfLink to="https://www.youtube.com/user/JAMFMedia" target="blank"><YouTubeIcon></YouTubeIcon></JamfLink>
          <JamfLink to="https://www.instagram.com/jamfsoftware/" target="blank"><InstagramIcon></InstagramIcon></JamfLink>
          <JamfLink to="https://www.facebook.com/jamfsoftware/" target="blank"><FacebookIcon></FacebookIcon></JamfLink>
        </div>
    </div>
  </div>
</template>

<script>
import { JamfLink } from "@jamf/design-system-vue";
import FacebookIcon from "./icons/FacebookIcon.vue";
import TwitterIcon from "./icons/TwitterIcon.vue";
import LinkedInIcon from "./icons/LinkedInIcon.vue";
import YouTubeIcon from "./icons/YouTubeIcon.vue";
import InstagramIcon from "./icons/InstagramIcon.vue";

export default {
  name: "Footer",
  components: {
    JamfLink,
    FacebookIcon,
    TwitterIcon,
    LinkedInIcon,
    YouTubeIcon,
    InstagramIcon,
  },
  data() {
    return {
      countryCode: "",
    };
  },
  methods: {
    async getLocation() {
      try {
        const response = await fetch("https://ipapi.co/json/");
        const data = await response.json();
        this.countryCode = data.country_code;
      } catch (error) {
        console.error("Error fetching location:", error);
      }
    },
  },
  async mounted() {
    await this.getLocation();
    console.log(this.countryCode);
  },
};
</script>

<style lang="scss">
@import "../assets/styles/main.scss";

.footer {
  display: flex;
  flex-direction: column;
  align-items: 100%;
  // font-family: proxima nova;
  align-items: center;
  margin-top: auto;
  padding-top: 120px;
}

.footer-content {
  display: flex;
  flex-direction: column;
  padding: 30px 200px 18px;
  background: #FFFFFF;
  width: 100%;
  align-items: center;
}

.footer-top-middle-container {
  max-width: 1500px;
}

.footer-top-container {
  display: flex;
  padding-bottom: 20px;
  border-bottom-style: solid;
  border-width: 1px;
  border-color: #E0E0E0;
}

.logo-container {
  justify-content: flex-start;
  padding-right: 138px;
}

.prod-disclaimer {
  padding-top: 8px
}

.footer-middle-container {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  border-bottom-style: solid;
  border-width: 1px;
  border-color: #E0E0E0;
}

.footer-left-col {
  display: flex;
  flex-direction: column;
  padding-top: 19px;
  min-width: 120px;
  // margin-right: 96px;
  gap: 8px;
}
.footer-left-col .link {
  align-items: flex-start;
  color: #00000095;
}
.footer-middle-col {
  display: flex;
  flex-direction: column;
  padding-top: 19px;
  padding-bottom: 19px;
  padding-right: 19px;
  min-width: 120px;
  gap: 8px;
}
.footer-middle-col .link {
  color: #00000095;
}

.footer-right-col {
  padding-left: 18px;
  padding-top: 18px;
  border-left-style: solid;
  border-width: 1px;
  border-color: #E0E0E0;
  height: 100%;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  line-height: 24px;
}

.footer-bottom-container {
  display: flex;
  background: #434A54;
  height: 74px;
  width: 100%;
  justify-content: space-around;
}
.footer-bar-left {
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: space-between;
}
.footer-bar-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #E0E0E0;
  gap: 6px;
  padding: 10px;
}
.footer-bar-left .link {
  color: #FFFFFF;
}
img {
  float: left;
  margin-right: 15px;
  //margin-left: 20px;
  height: 37px;
  width: 105px;
  object-fit: cover;
}

#cleverbridge-copyright {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6)
}

#compliance-logo {
  padding-top: 12px;
}

.help-content {
  border-top: solid 1px #E0E0E0;
  padding-top: 65px;
  padding-bottom: 151px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  // width: 1120px;
  width: 80%;
}

#contact-link {
  color: rgba(30, 121, 233, 1);
  border: solid 2px rgba(30, 121, 233, 1);
  background-color: white;
}

@media screen and (max-width: 1120px) {
  .footer-content {
    display: flex;
    flex-direction: column;
    padding: 30px 50px 18px;
    background: #FFFFFF;
    width: 100%;
    align-items: center;
  }

  .logo-container {
    justify-content: flex-start;
    padding-right: 30px;
  }

  .footer-right-col {
    justify-content: center;
    padding-left: 0px;
    padding-top: 18px;
    height: 100%;
    font-size: 12px;
    border: none
  }

  .help-content {
    border-top: solid 1px #E0E0E0;
    padding-top: 65px;
    padding-bottom: 151px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    // width: 700px;
    width: 45%;
  }

  .footer-middle-container {
    display: grid;
    grid-template-columns: 1fr;
    border: none;
  }
}

@media screen and (max-width: 900px) {
  .footer-bar-left {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-between;
}
}

@media screen and (max-width: 820px) {
  .footer-bar-left {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: column;
  height: 100%;
}

.footer-bottom-container {
    display: flex;
    background: #434A54;
    height: 74px;
    width: 100%;
    height: 100%;
    justify-content: space-around;
    padding: 30px 0 30px;
    flex-direction: column-reverse;
    align-items: center;
    gap: 20px;
  }
}

@media screen and (max-width: 820px) {
  .help-content {
    border-top: solid 1px #E0E0E0;
    padding-top: 65px;
    padding-bottom: 151px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    width: 500px;
  }
}

@media screen and (max-width: 600px) {
  .help-content {
    border-top: solid 1px #E0E0E0;
    padding-top: 65px;
    padding-bottom: 151px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    width: 420px;
  }

  .footer-top-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    border-bottom-style: solid;
    border-width: 1px;
    border-color: #E0E0E0;
  }
}

@media screen and (max-width: 455px) {
  .help-content {
    border-top: solid 1px #E0E0E0;
    padding-top: 65px;
    padding-bottom: 151px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    width: 300px;
  }

  .footer-content {
    display: flex;
    flex-direction: column;
    padding: 30px 20px 18px 10px;
    background: #FFFFFF;
    width: 375px;
    align-items: center;
  }

  .footer-bottom-container {
    display: flex;
    background: #434A54;
    height: 74px;
    width: 375px;
    height: 100%;
    justify-content: space-around;
    padding: 30px 0 30px;
    flex-direction: column-reverse;
    align-items: center;
    gap: 20px;
  }
}


</style>
