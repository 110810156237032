<template>
    <div class="icon">
        <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.69 1.6848C16.4349 1.42667 16.1173 1.23911 15.768 1.14046C14.4599 0.786153 9.19569 0.786153 9.19569 0.786153C9.19569 0.786153 3.94647 0.77716 2.62517 1.14106C2.27592 1.23971 1.95824 1.42727 1.70314 1.6854C1.44648 1.94462 1.2607 2.26541 1.16359 2.61703C0.915613 3.9705 0.793393 5.34401 0.798493 6.72C0.794768 8.0903 0.917364 9.45835 1.16473 10.8065C1.2618 11.1582 1.44761 11.4791 1.70434 11.7384C1.95944 11.9965 2.27712 12.1841 2.62637 12.2828C3.93268 12.6377 9.19869 12.6377 9.19869 12.6377C9.19869 12.6377 14.4485 12.6377 15.771 12.2828C16.1203 12.1841 16.4379 11.9966 16.693 11.7384C16.9497 11.4792 17.1355 11.1584 17.2326 10.8068C17.4744 9.45795 17.5914 8.08971 17.5821 6.7194C17.5914 5.34397 17.4734 3.97046 17.2296 2.61659L17.2296 2.61643C17.1325 2.26481 16.9467 1.94402 16.69 1.6848ZM7.51696 4.17333L11.8981 6.71941L7.51696 9.25709V4.17333Z" fill="white"/>
        </svg>
    </div>
</template>

<style lang="scss">
@import "../../assets/styles/main.scss";

.icon {
    // color: #8A8F96;
    background-color: rgba(250, 251, 252, 0.1);
    display: flex;
    justify-content: center;
    border-radius: 4px;
    height: 30px;
    width: 30px;  
}

</style>