<template>
    <div class="icon">
        <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.6346 3.12414C16.3525 2.69282 16.8899 2.01629 17.1478 1.21953C16.4695 1.61434 15.7305 1.89409 14.9608 2.04744C13.9954 1.0188 12.4999 0.683832 11.188 1.20241C9.87604 1.72099 9.01372 2.98798 9.01256 4.39867C9.01256 4.6636 9.04236 4.92724 9.10139 5.18505C6.33424 5.05262 3.7542 3.74884 2.00621 1.59961C1.69768 2.12396 1.53673 2.72199 1.5404 3.33036C1.53934 4.48156 2.11376 5.55693 3.07105 6.19606C2.52451 6.17865 1.99004 6.03084 1.51222 5.76494L1.51222 5.8075C1.51124 7.44599 2.66472 8.85802 4.27019 9.18394C3.76541 9.32014 3.2364 9.34061 2.72261 9.24383C3.176 10.6459 4.46925 11.6056 5.94252 11.6334C4.72519 12.5888 3.22213 13.1073 1.67468 13.1058C1.40224 13.1052 1.13005 13.0894 0.859375 13.0585C2.43724 14.0677 4.27154 14.603 6.14456 14.601C12.4771 14.601 15.9356 9.35834 15.9356 4.81892C15.9356 4.67265 15.9356 4.52517 15.9254 4.37829C16.6006 3.89269 17.1832 3.28982 17.6454 2.59838L17.613 2.58459C16.9836 2.86066 16.317 3.04246 15.6346 3.12414Z" fill="white"/>
        </svg>
    </div>
</template>

<style lang="scss">
@import "../../assets/styles/main.scss";

.icon {
    // color: #8A8F96;
    background-color: rgba(250, 251, 252, 0.1);
    display: flex;
    justify-content: center;
    border-radius: 4px;
    height: 30px;
    width: 30px;  
}

</style>
<script>
import { JamfLink } from "@jamf/design-system-vue";

export default {
  name: "Footer",
  components: {
    JamfLink,
  },
  props: {},
};
</script>

