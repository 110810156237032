<template>
    <div class="icon">
        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.38369 9.51612H8.85843L9.22892 6.53241H6.38369V4.6278C6.38369 3.76392 6.6145 3.17521 7.8075 3.17521H9.32903V0.508637C8.59251 0.426864 7.85195 0.386832 7.11091 0.388737C4.91731 0.388737 3.41557 1.77898 3.41557 4.33225V6.53241H0.936035V9.51612H3.41677V17.1717L6.38369 17.1711V9.51612Z" fill="white"/>
        </svg>
    </div>
</template>

<style lang="scss">
@import "../../assets/styles/main.scss";

.icon {
    // color: #8A8F96;
    background-color: rgba(250, 251, 252, 0.1);
    display: flex;
    justify-content: center;
    border-radius: 4px;
    height: 30px;
    width: 30px;  
}

</style>

